import Link from 'next/link'
import { Twitter, GitHub, Hash, Mail } from 'react-feather'
import tw from 'twin.macro'

const Main = tw.div`
      flex 
      flex-col 
      w-full 
      justify-center 
      items-center 
      text-gray-400
`

const Copyright = tw.div`
      m-2
      text-center
`

const IconRow = tw.div`
      flex
      flex-row  
      justify-center 
      items-center
`

const Icon = tw.a`
      m-2
`

const LinkRow = tw.div`
      flex
      flex-row
      justify-center 
      items-center
`

const LinkBtn = tw.a`
      m-2
`

const Footer = () => {
  return (
    <Main>
      <LinkRow>
        <Link href={'https://blog.meerkt.com'} passHref>
          <LinkBtn>Blog</LinkBtn>
        </Link>
        <Link href={'https://status.meerkt.com'} passHref>
          <LinkBtn>Status</LinkBtn>
        </Link>
      </LinkRow>
      <IconRow>
        <Link href={'https://twitter.com/meerkt'} passHref>
          <Icon>
            <Twitter />
          </Icon>
        </Link>
        <Link href={'https://medium.com/meerkt'} passHref>
          <Icon>
            <Hash />
          </Icon>
        </Link>
        <Link href={'https://github.com/Meerkt'} passHref>
          <Icon>
            <GitHub />
          </Icon>
        </Link>
        <Link href={'mailto:hello@meerkt.com'} passHref>
          <Icon>
            <Mail />
          </Icon>
        </Link>
      </IconRow>
      <Copyright>
        &copy; {new Date().getFullYear()} Meerkt. All rights reserved.
      </Copyright>
    </Main>
  )
}

export default Footer
