import '../styles/globals.css'
import 'nprogress/nprogress.css'
import type { AppProps } from 'next/app'
import { Router } from 'next/router'
import Navbar from '../components/navbar'
import Head from 'next/head'
import tw from 'twin.macro'
import Footer from '../components/Footer'
const NProgress = require('nprogress')
import { GlobalStyles } from 'twin.macro'

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const Main = tw.div`
      h-screen
`

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Meerkt</title>
        <link rel="icon" type="image/x-icon" href={'/favicon.ico'} />
        <link rel="shortcut icon" type="image/x-icon" href={'/favicon.ico'} />
        <script async data-api={'/_hive'} src={'/bee.js'} />
        <link
          rel="search"
          type="application/opensearchdescription+xml"
          title="Meerkt Search"
          href={'/opensearch.xml'}
        />
      </Head>
      <GlobalStyles />
      <Main>
        <Navbar />
        <Component {...pageProps} />
        <Footer />
      </Main>
    </>
  )
}
export default MyApp
